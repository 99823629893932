@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400&display=swap');

@tailwind base;

/* Firefox */
/* NEEDS EDITING */
/* * {
  scrollbar-width: thin;
  scrollbar-color: white;
} */

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 18px;
  background-color: #3d5634;
}

/* *::-webkit-scrollbar-track {
  background-color: #3d5634;
} */

*::-webkit-scrollbar-thumb {
  background-color: white;
  border: 5px solid #3d5634;
  border-radius: 10px;
  right: 20px;
}

@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Apparel Display";
    src: url("./assets/fonts/APPAREL DISPLAY REGULAR.TTF") format("ttf");
  }
}
